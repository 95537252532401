import React from 'react';
import { connect } from 'react-redux';
import MaterialIcon from 'material-icons-react';
import { createPortal } from 'react-dom';
// import DOMPurify from 'dompurify';
import RenderIf from '../../../ui-components/Common';
import { /* SuccessPage, */ FooterContent } from '../../../ui-components/PageContent';
import {
  respondUserProjectQuestionnaire,
  setFirstTimeUserFalse,
  undoResponseQuestion,
  updateLastQuestionCardId,
} from '../actions/questionnaire';
import '../styles/questionaire.scss';
import questionnaireConstant from '../actions/constants';
import CardHelper from '../../../helpers/cardHelper';
import './Swipeable.scss';
import orangeBg from '../../../../assets/images/orange-bg.png';
import CheckSoftKeyBoard from './CheckSoftKeyBoard';
import checkDeviceType from '../../../utils/helper';
import InstructionalOverlay from './InstructionalOverlay';
import InstructionalOverlayHelp from './components/InstructionOverlayHelp';

class CardComponent extends React.Component {
  constructor(props) {
    super(props);
    this.openTextResponseValue = React.createRef();
    this.optionalCommentValue = React.createRef();
    this.state = {
      responseText: null,
      totalCards: 0,
      submitButtonActive: false,
      submitButtonDisable: false,
      isTablet: false,
      currentCardId: 0,
      showErrorMessage: false,
      isSpinnerLoading: true,
      isButtonClicked: false,
      clickedButton: '',
      isKeyPress: false,
      isOpenTextSubmiting: false,
      showOptionalComment: true,
      isQuestionPolarity: false,
      isUndoResponse: false,
      undoResponseClicked: true,
      lastCardId: 0,
      lastQuestionType: null,
      lastCard: {},
      timer: null,
      isSkipButtonClicked: false,
      optionalCommentCount: 0,
      warningMessage: '',
      onCardLeftSwipeEffect: false,
      onCardRightSwipeEffect: false,
      isInstructionOverlayOpen: false,
      mounted: false,
      limitExceed: false,
      limitExceedIn: true,
    };
    this.exccedLimit = this.exccedLimit.bind(this);
    this.respondQuestion = this.respondQuestion.bind(this);
    this.undoResponse = this.undoResponse.bind(this);
    this.skipQuestion = this.skipQuestion.bind(this);
    this.submitResponse = this.submitResponse.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleEnter = this.handleEnter.bind(this);
    this.setFirstTimeUserFalse = this.setFirstTimeUserFalse.bind(this);
    this.handleOutSideClick = this.handleOutSideClick.bind(this);
    this.stopKeyboardPress = this.stopKeyboardPress.bind(this);
    this.handleOptionalCommentChange = this.handleOptionalCommentChange.bind(this);
  }

  // DOM
  swiper = document.querySelector('#swiper');

  componentDidMount() {
    this.appendCardRows();
    this.setState({ mounted: true });
    const { currentQuestionType } = this.props.questionnaire;
    // this.toggleInstructionOverlay(true);
    this.toggleInstructionOverlay(this.props.isNewUserState
      && currentQuestionType === questionnaireConstant.YES_NO_QUESTION_TYPE);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.questionnaire
      && prevProps.questionnaire.userQuestionsData !== this.props.questionnaire.userQuestionsData
      && this.props.questionnaire.userQuestionsData.length > 0) {
      this.appendCardRows();
      window.scrollTo({ top: 0 });
    }
    if (this.state.limitExceed !== prevState.limitExceed) {
      this.appendCardRows();
      window.scrollTo({ top: 0 });
    }
  }


  appendCardRows = () => {
    const isMobileDevice = checkDeviceType();
    this.setState({
      device: isMobileDevice,
    });
    const { totalCards, currentCardIndex, userQuestionsData } = this.props.questionnaire;
    // eslint-disable-next-line no-undef
    swiper.innerHTML = '<div id="instruction-overlay-div" class="question-card c-card"><div>';
    userQuestionsData.map((cardData, index) => this.appendNewCard(
      currentCardIndex,
      index,
      cardData.title,
      totalCards,
      cardData.type,
      userQuestionsData.length,
      isMobileDevice,
    ));
  }


  // functions
  appendNewCard = (
    currentCardIndex,
    cardIndex,
    cardTitle,
    totalCard,
    currentQuestionType,
    remainingCards, isMobileDevice,
  ) => {
    const charExceedLimit = this.state.limitExceed === true;
    if (isMobileDevice.length > 0) {
      const cCardDataContainerHeight = 40;
      let fontSize = 18;
      const MaxChraterSize = 100;
      if (cardTitle.length > MaxChraterSize) {
        fontSize -= parseInt(cardTitle.length / cCardDataContainerHeight, 10);
      }
      fontSize = `${fontSize}px`;
      const card = new CardHelper({
        currentCardIndex,
        cardIndex,
        cardTitle,
        totalCard,
        currentQuestionType,
        remainingCards,
        fontSize,
        charExceedLimit,
        onLike: () => {
          this.respondQuestion(true, questionnaireConstant.BUTTON_TYPE.YES, false);
        },
        onDislike: () => {
          this.respondQuestion(false, questionnaireConstant.BUTTON_TYPE.NO, false);
        },
        onSwipeRight: () => {
          this.setState({ onCardRightSwipeEffect: true, onCardLeftSwipeEffect: false });
        },
        onSwipeLeft: () => {
          this.setState({ onCardLeftSwipeEffect: true, onCardRightSwipeEffect: false });
        },
      });
      // eslint-disable-next-line no-undef
      swiper.append(card.element);
      // eslint-disable-next-line no-undef
      const cards = swiper.querySelectorAll('.question-card:not(.dismissing)');
      cards.forEach((cardd, index) => {
        cardd.style.setProperty('--i', index);
      });
    } else {
      const cCardDataContainerHeight = 106;
      let fontSize = 18;
      const maxchractersize = 250;
      if (cardTitle.length > maxchractersize) {
        fontSize -= parseInt(cardTitle.length / cCardDataContainerHeight, 10);
      }
      fontSize = `${fontSize}px`;
      const card = new CardHelper({
        currentCardIndex,
        cardIndex,
        cardTitle,
        totalCard,
        currentQuestionType,
        remainingCards,
        fontSize,
        charExceedLimit,
        onLike: () => {
          this.respondQuestion(true, questionnaireConstant.BUTTON_TYPE.YES);
        },
        onDislike: () => {
          this.respondQuestion(false, questionnaireConstant.BUTTON_TYPE.NO);
        },
        onSwipeRight: () => {
          this.setState({ onCardRightSwipeEffect: true, onCardLeftSwipeEffect: false });
        },
        onSwipeLeft: () => {
          this.setState({ onCardLeftSwipeEffect: true, onCardRightSwipeEffect: false });
        },
      });
      // eslint-disable-next-line no-undef
      swiper.append(card.element);

      // eslint-disable-next-line no-undef
      const cards = swiper.querySelectorAll('.question-card:not(.dismissing)');
      cards.forEach((cardd, index) => {
        cardd.style.setProperty('--i', index);
      });
    }
  }

  stopKeyboardPress() {
    this.setState({ isKeyPress: true });
  }

  handleOutSideClick(event) {
    if (this.openTextResponseValue.current !== null) {
      // eslint-disable-next-line
      event.target.tagName === 'INPUT'
        ? this.openTextResponseValue.current.focus()
        : this.openTextResponseValue.current.blur();
    }
  }

  setFirstTimeUserFalse() {
    const { token } = this.state;
    const data = {
      token,
    };
    this.props.setFirstTimeUserFalse(data);
  }

  handleEnter(event) {
    const { responseText } = this.state;
    if (event.target.value.length > questionnaireConstant.OPTIONAL_COMMENT_LIMIT) {
      event.preventDefault();
    } else {
      if (event.key === 'Enter' && responseText !== null && responseText !== undefined && responseText.trim() !== '') {
        this.submitResponse();
      }
      if (event.key === 'Enter' && (responseText === null || responseText === undefined || responseText.trim() === '')) {
        this.setState({ showErrorMessage: true });
        setTimeout(() => {
          this.setState({ showErrorMessage: false });
        }, 1500);
      }
    }
  }

  handleChange(event) {
    this.setState({ optionalCommentCount: event.target.value.length });
    if (event.target.value !== '') {
      if (event.target.value.length > 0) {
        this.setState({ optionalCommentCount: event.target.value.length });
        this.setState({ warningMessage: questionnaireConstant.WARNING_MESSAGE });
      } else {
        this.setState({ optionalCommentCount: 0 });
        this.setState({ warningMessage: '' });
      }
    }
    if (event.target.value !== null && event.target.value !== undefined && event.target.value.trim() !== '' && event.key !== 'Enter') {
      if (event.target.value.length > questionnaireConstant.OPTIONAL_COMMENT_LIMIT) {
        this.setState({
          responseText: event.target.value,
          submitButtonActive: true,
          showErrorMessage: false,
        });
      } else {
        this.setState({
          responseText: event.target.value,
          submitButtonActive: true,
          showErrorMessage: false,
        });
      }
    } else {
      this.setState({ submitButtonDisable: true });
      setTimeout(() => {
        this.setState({
          responseText: null,
          submitButtonActive: false,
          submitButtonDisable: false,
        });
      }, 300);
    }
  }

  handleOptionalCommentChange(event) {
    const responseData = event.target.value;
    const formattedResponseText = responseData === null
      ? null
      : responseData.trim();
    const optionalCommentValueLimit = formattedResponseText.length;
    this.setState({ optionalCommentCount: optionalCommentValueLimit });
    if (event.target.value !== '') {
      if (optionalCommentValueLimit > 0) {
        this.setState({ optionalCommentCount: optionalCommentValueLimit });
        this.setState({ warningMessage: questionnaireConstant.WARNING_MESSAGE });
      } else {
        this.setState({ optionalCommentCount: 0 });
        this.setState({ warningMessage: '' });
      }
    }

    if (formattedResponseText !== '') {
      this.setState({
        responseText: formattedResponseText,
      });
    } else {
      this.setState({
        responseText: null,
      });
    }
  }

  submitResponse() {
    const { responseText } = this.state;
    if (responseText !== null && responseText !== undefined && responseText.trim() !== '') {
      setTimeout(() => {
        this.setState({ isOpenTextSubmiting: true });
        this.respondQuestion(null);
      }, 300);
    }
  }

  async exccedLimit() {
    if (sessionStorage.length > 0) {
      this.setState({
        limitExceed: true,
      });
    }
  }

  async limitIn() {
    if (sessionStorage.length > 0 && this.state.limitExceed === true) {
      this.setState({
        limitExceed: false,
      });
    }
  }

  skipQuestion() {
    this.setState({ isKeyPress: false, isOpenTextSubmiting: true, optionalCommentCount: 0 });
    if (!this.state.isKeyPress && !this.state.isSkipButtonClicked) {
      this.setState({ isSkipButtonClicked: true });
      this.respondQuestion(null, questionnaireConstant.BUTTON_TYPE.SKIP, true);
    }
  }

  manageUndoResponse(index, current) {
    this.props.updateLastQuestionCardId(index, current, current.type);
    this.setState({ lastCardId: index, lastCard: current, lastQuestionType: current.type });
  }

  cardRotateAnimationHandler = (responseBool, responseText) => {
    if (navigator.userAgent.match(/Android/i)) {
      if (responseBool === true) {
        const el = document.querySelectorAll('.active-card.fade-in.c-card');
        el[el.length - 1].style.cssText = 'animation: AndroidSwipeRight 2s 1; animation-direction: normal;';
      } else if (responseBool === false) {
        const el = document.querySelectorAll('.active-card.fade-in.c-card');
        el[el.length - 1].style.cssText = 'animation: AndroidSwipeLeft 2s 1; animation-direction: normal;';
      } else if (responseText) {
        const el = document.querySelectorAll('.active-card.fade-in.c-card');
        el[el.length - 1].style.cssText = 'animation: AndroidSwipeRight 2s 1; animation-direction: normal;';
      } else {
        const el = document.querySelectorAll('.active-card.fade-in.c-card');
        el[el.length - 1].style.cssText = 'animation: AndroidSwipeTop 2s 1; animation-direction: normal;';
      }
    } else if (navigator.userAgent.match(/iPhone/i)) {
      if (responseBool === true) {
        const el = document.querySelectorAll('.active-card.fade-in.c-card');
        el[el.length - 1].style.cssText = 'animation: IOSswipeRight 1s 1; animation-direction: normal;';
      } else if (responseBool === false) {
        const el = document.querySelectorAll('.active-card.fade-in.c-card');
        el[el.length - 1].style.cssText = 'animation: IOSswipeLeft 1s 1; animation-direction: normal;';
      } else if (responseText) {
        const el = document.querySelectorAll('.active-card.fade-in.c-card');
        el[el.length - 1].style.cssText = 'animation: IOSswipeRight 1s 1; animation-direction: normal;';
      } else {
        const el = document.querySelectorAll('.active-card.fade-in.c-card');
        el[el.length - 1].style.cssText = 'animation: IOSswipeTop 1s 1; animation-direction: normal;';
      }
    } else if (responseBool === true) {
      const el = document.querySelectorAll('.active-card.fade-in.c-card');
      el[el.length - 1].style.cssText = 'animation: swipeRight 1.5s 1; animation-direction: normal;';
    } else if (responseBool === false) {
      const el = document.querySelectorAll('.active-card.fade-in.c-card');
      el[el.length - 1].style.cssText = 'animation: swipeLeft 1.5s 1; animation-direction: normal;';
    } else if (responseText) {
      const el = document.querySelectorAll('.active-card.fade-in.c-card');
      el[el.length - 1].style.cssText = 'animation: swipeRight 1.5s 1; animation-direction: normal;';
    } else {
      const el = document.querySelectorAll('.active-card.fade-in.c-card');
      el[el.length - 1].style.cssText = 'animation: swipeTop 2s 1; animation-direction: normal;';
    }
  }

  async respondQuestion(responseBool, type) {
    clearInterval(this.state.timer);
    this.manageUndoResponse(
      this.props.questionnaire.currentCard.id,
      this.props.questionnaire.currentCard,
    );
    this.setState({
      isKeyPress: false,
      showOptionalComment: false,
      isSkipButtonClicked: true,
      optionalCommentCount: 0,
    });
    if (type) {
      this.setState({
        isButtonClicked: true,
        clickedButton: type,
      });
      setTimeout(() => {
        this.setState({
          isButtonClicked: false, clickedButton: '',
        });
      }, 300);
    }
    this.setState({
      onCardLeftSwipeEffect: false, onCardRightSwipeEffect: false,
    });
    if (!this.state.isKeyPress) {
      const {
        responseText,
      } = this.state;
      const { token } = this.props;
      const { userQuestionsData, currentCard } = this.props.questionnaire;
      if (userQuestionsData.length > 0) {
        const data = {
          questionUserId: currentCard.id,
          responseBool,
          token,
          responseText,
          type,
        };
        this.setState({
          currentCardId: currentCard.id,
          submitButtonActive: false,
          responseText: null,
          isQuestionPolarity: true,
        });
        setTimeout(() => {
          if (this.openTextResponseValue.current !== null) {
            this.openTextResponseValue.current.focus();
          }
        }, 2000);
        await this.props.respondUserProjectQuestionnaire(data, this.props.questionnaire);
        this.setState({ undoResponseClicked: false });
        this.props.updateUndoResponseClickHandler(false);
        const timer = setTimeout(() => {
          this.setState({ undoResponseClicked: true });
          this.props.updateUndoResponseClickHandler(true);
        }, 7000);
        this.setState({ timer });
        this.setState({
          isQuestionPolarity: false,
          isOpenTextSubmiting: false,
          showOptionalComment: true,
          responseText: null,
          isSkipButtonClicked: false,
        });
      }
    }
  }

  async undoResponse(type) {
    clearInterval(this.state.timer);

    this.setState({
      isKeyPress: false,
      showOptionalComment: false,
    });
    if (type === 'undo') {
      this.setState({
        clickedButton: type,
        undoResponseClicked: true,
      });
      this.props.updateUndoResponseClickHandler(true);
    }
    const {
      token,
    } = this.props;
    this.setState({
      currentCardId: this.state.lastCardId,
    });
    await this.props.undoResponseQuestion(
      {
        questionUserId: this.state.lastCardId,
        token,
      },
      this.props.questionnaire,
      this.state.lastCard,
      this.state.lastQuestionType,
    ).then(() => {
      this.setState({
        isQuestionPolarity: false,
        isOpenTextSubmiting: false,
        showOptionalComment: true,
        responseText: null,
        undoResponseClicked: true,
      });
      this.props.updateUndoResponseClickHandler(true);
      this.appendCardRows();
    });
  }

  toggleInstructionOverlay = (value) => {
    const element = document.getElementById('instruction-overlay-div');
    if (value === true) {
      element.classList.add('inst-over-trans');
    } else {
      element.classList.remove('inst-over-trans');
    }
    this.setState({ isInstructionOverlayOpen: value });
  }

  render() {
    const {
      userQuestionsData, isValidGuid, successfulClearBacklog, currentQuestionType, totalCards,
      currentCardIndex, questionPolarity, isNewUser,
    } = this.props.questionnaire;
    //    const  { isTablet } = this.props;

    const {
      submitButtonActive, submitButtonDisable, showErrorMessage,
      isButtonClicked, clickedButton, isTablet, isOpenTextSubmiting, showOptionalComment,
      responseText, isQuestionPolarity, isSkipButtonClicked, optionalCommentCount, warningMessage,
      onCardLeftSwipeEffect, onCardRightSwipeEffect, isInstructionOverlayOpen, mounted,
    } = this.state;
    const responsetext = responseText || '';
    return (
      <React.Fragment>
        <InstructionalOverlay
          isOpen={isInstructionOverlayOpen}
          toggleInstructionOverlay = {this.toggleInstructionOverlay}
        />
        <RenderIf showComponent={
          isValidGuid && !successfulClearBacklog
          && userQuestionsData.length > 0}>
          <div className="cards-wrapper fade-in-getting-started" onClick={this.handleOutSideClick}>
            <div className="card-section">
              <img src={orangeBg} className='orange-bg-image' />
              {currentQuestionType === questionnaireConstant.YES_NO_QUESTION_TYPE
              && (<InstructionalOverlayHelp
                    toggleInstructionOverlay = {this.toggleInstructionOverlay}
                  />)}
              <div className="container" id="question-container">
                <div className="row">
                  <div className="col-md-12">
                    <div className="question-content-container">
                      <div className="c-card-container">
                        <div id='swiper'>
                        {(isNewUser || isInstructionOverlayOpen)
                          && mounted && createPortal(
                            <div class="swipe-text swipe-un-auth-text d-flex right">
                                Swipe right if you agree
                                <MaterialIcon icon="eastt"
                                  size={36}
                                  color={'#ffffff'}/>
                              </div>,
                            document.getElementById('instruction-overlay-div'),
                        )}
                          {(isNewUser || isInstructionOverlayOpen)
                          && mounted && createPortal(
                            <div class="swipe-text swipe-un-auth-text d-flex left">
                              <MaterialIcon icon="west"
                                size={36}
                                color={'#ffffff'}/>
                              Swipe left if you disagree
                            </div>,
                            document.getElementById('instruction-overlay-div'),
                          )}
                        </div>
                      </div>
                      <div className="response-buttons-container">
                        {currentQuestionType === questionnaireConstant.YES_NO_QUESTION_TYPE
                          && (<div className="row justify-content-center">
                            <div className="col-md-12">
                              <div className="open-text-input-container fixheight add-comment">
                                {showOptionalComment
                                  && <input disabled={!showOptionalComment} placeholder="Add optional comment..." type="text" ref={this.optionalCommentValue} onChange={this.handleOptionalCommentChange} className="open-text-input comment-box cus_input_info" />
                                }
                                {optionalCommentCount > questionnaireConstant.OPTIONAL_COMMENT_LIMIT
                                  && <span className="limit_error_msg">{warningMessage}</span>}
                                <span className={optionalCommentCount > questionnaireConstant.OPTIONAL_COMMENT_LIMIT ? 'limit_char error-txt' : 'limit_char'}>{optionalCommentCount}/{questionnaireConstant.OPTIONAL_COMMENT_LIMIT} </span>
                                <div id = 'add-optional-comments' />
                                  {(isNewUser || isInstructionOverlayOpen)
                                   && mounted && createPortal(
                                      <div class="swipe-text swipe-un-auth-text comment">
                                        <MaterialIcon icon="north_west"
                                          size={36}
                                          color={'#ffffff'}/>
                                        Respond with comment
                                      </div>,
                                      document.getElementById('add-optional-comments'),
                                  )}
                                <span onFocus={optionalCommentCount > questionnaireConstant.OPTIONAL_COMMENT_LIMIT ? this.exccedLimit(true) : this.limitIn(true)} className={optionalCommentCount > questionnaireConstant.OPTIONAL_COMMENT_LIMIT ? 'limit_char error-txt' : 'limit_char'}>{optionalCommentCount}/{questionnaireConstant.OPTIONAL_COMMENT_LIMIT} </span>
                              </div>
                            </div>
                            <div className="col-md-8">
                              <div className="row justify-content-center align-items-center">
                                <div className="col-4">
                                  {(!isQuestionPolarity && questionPolarity)
                                    && <button
                                      disabled={optionalCommentCount
                                        > questionnaireConstant.OPTIONAL_COMMENT_LIMIT}
                                      onKeyPress={this.stopKeyboardPress}
                                      onClick={() => {
                                        this.cardRotateAnimationHandler(false, null);
                                        setTimeout(() => {
                                          this.respondQuestion(
                                            false, questionnaireConstant.BUTTON_TYPE.NO, true,
                                          );
                                        }, 500);
                                      }} className={`response-button btn-disagree disabled-element ${onCardLeftSwipeEffect === true ? 'hoverAddedAgree' : ''} ${isTablet && isButtonClicked && clickedButton === questionnaireConstant.BUTTON_TYPE.NO ? 'hoverAdded' : ''} ${isTablet ? '' : 'desktop-polarity-hover'}`}>
                                      <MaterialIcon icon="thumb_down" size={36} color={'#4eb956'} />
                                      <small className="t-btn-text btn-disagree" style={{ fontSize: '10px' }}>Disagree</small>
                                    </button>}
                                  {(!isQuestionPolarity && !questionPolarity)
                                    && <button
                                      disabled={optionalCommentCount
                                        > questionnaireConstant.OPTIONAL_COMMENT_LIMIT}
                                      onKeyPress={this.stopKeyboardPress} onClick={() => {
                                        this.cardRotateAnimationHandler(false, null);
                                        setTimeout(() => {
                                          this.respondQuestion(false,
                                            questionnaireConstant.BUTTON_TYPE.NO, true);
                                        }, 500);
                                      }} className={`response-button btn-disagree disabled-element ${onCardLeftSwipeEffect === true ? 'hoverAddedDisagree' : ''} ${isTablet && isButtonClicked && clickedButton === questionnaireConstant.BUTTON_TYPE.NO ? 'hoverAdded' : ''} ${isTablet ? '' : 'desktop-hover'}`}>
                                      <MaterialIcon icon="thumb_down" size={36} color={'#dc615b'} />
                                      <small className="t-btn-text btn-agree" style={{ fontSize: '10px' }}>Disagree</small>
                                    </button>}
                                </div>
                                <div className="col-4">
                                  {(!isQuestionPolarity && questionPolarity) && <button
                                    onClick={() => {
                                      this.cardRotateAnimationHandler(true, null);
                                      setTimeout(() => {
                                        this.respondQuestion(true,
                                          questionnaireConstant.BUTTON_TYPE.YES, true);
                                      }, 500);
                                    }
                                    } onKeyPress={this.stopKeyboardPress} disabled={optionalCommentCount > questionnaireConstant.OPTIONAL_COMMENT_LIMIT} className={`response-button btn-agree disabled-element ${onCardRightSwipeEffect === true ? 'hoverAddedDisagree' : ''} ${isTablet && isButtonClicked && clickedButton === questionnaireConstant.BUTTON_TYPE.YES ? 'hoverAdded' : ''} ${isTablet ? '' : 'desktop-polarity-hover'}`}>
                                    <MaterialIcon icon="thumb_up" size={38} color={'#dc615b'} />
                                    <small className="t-btn-text btn-agree" style={{ fontSize: '10px' }}>Agree</small>
                                  </button>}
                                  {(!isQuestionPolarity && !questionPolarity) && <button
                                    onClick={() => {
                                      this.cardRotateAnimationHandler(true, null);
                                      setTimeout(() => {
                                        this.respondQuestion(true,
                                          questionnaireConstant.BUTTON_TYPE.YES, true);
                                      }, 500);
                                    }}
                                    onKeyPress={this.stopKeyboardPress} disabled={optionalCommentCount > questionnaireConstant.OPTIONAL_COMMENT_LIMIT} className={`response-button btn-agree disabled-element ${onCardRightSwipeEffect === true ? 'hoverAddedAgree' : ''} ${isTablet && isButtonClicked && clickedButton === questionnaireConstant.BUTTON_TYPE.YES ? 'hoverAdded' : ''} ${isTablet ? '' : 'desktop-hover'}`}>
                                    <MaterialIcon icon="thumb_up" size={38} color={'#4eb956'} />
                                    <small className="t-btn-text btn-disagree" style={{ fontSize: '10px' }}>Agree</small>
                                  </button>}
                                </div>
                              </div>
                            </div>
                            <div>
                            </div>


                          </div>)}

                        {/* open text question */}
                        {currentQuestionType === questionnaireConstant.OPEN_TEXT_QUESTION_TYPE && (<div className="row justify-content-center">
                          <div className="col-md-12">
                            <div className="open-text-input-container">
                              <input value={responsetext} disabled={isOpenTextSubmiting} placeholder="Type your answer here" type="text" ref={this.openTextResponseValue} onChange={this.handleChange} onKeyPress={this.handleEnter} className="open-text-input cus_input_info" autoFocus />
                              {optionalCommentCount > questionnaireConstant.OPTIONAL_COMMENT_LIMIT
                                && <span className="limit_error_msg2">{warningMessage}</span>}
                              <span onFocus={optionalCommentCount > questionnaireConstant.OPTIONAL_COMMENT_LIMIT ? this.exccedLimit(true) : this.limitIn(true)} className={optionalCommentCount > questionnaireConstant.OPTIONAL_COMMENT_LIMIT ? 'limit_char2 error-txt' : 'limit_char2'}>{optionalCommentCount} / {questionnaireConstant.OPTIONAL_COMMENT_LIMIT}</span>

                              {showErrorMessage && (<span className="error-message">Please type a response or tap "Skip this question" to continue.</span>)}
                              {submitButtonActive && (<span className={`fade-in-button ${submitButtonDisable ? 'fade-out-button' : ''}`}><button onClick={() => {
                                this.cardRotateAnimationHandler(null, null);
                                this.submitResponse();
                              }} style={{ outline: 'unset' }} className={optionalCommentCount > questionnaireConstant.OPTIONAL_COMMENT_LIMIT ? 'a-btn a-btn-primary a-btn-lg custom-button cstm-btn-disbled mt-3' : 'a-btn a-btn-primary a-btn-lg custom-button mt-3'}>
                                <span className="mr-2">OK</span>
                                <MaterialIcon icon="check" size={25} color={optionalCommentCount > questionnaireConstant.OPTIONAL_COMMENT_LIMIT ? '#8e8c8c' : '#fff'} />

                              </button>

                                {!isTablet && <span className="press-enter">press ENTER</span>} </span>)}

                            </div>
                          </div>
                        </div>)}
                      </div>
                      {!this.state.undoResponseClicked && (
                        <div className="row justify-content-center">
                          <div class="mx-auto offset-md-2">
                            <button
                              onClick={() => this.undoResponse(
                                questionnaireConstant.BUTTON_TYPE.UNDO,
                              )
                              }
                              onKeyPress={this.stopKeyboardPress}
                              className="undo-response-button"
                            >
                              <small className="t-btn-text btn-undo">
                                <span className="arrowUndo">↻</span> Undo Response
                              </small>
                            </button>
                          </div>
                        </div>
                      )}
                      <div className="progress-bar-section">
                        <div className="row justify-content-center">
                          <div className="col-md-10 col-lg-10">
                            <div className="progress-bar-container">

                              <div className='progress-bar-container-div'>
                                <div className='bottom-progress'>
                                <div id='bottom-progressbar-container' />
                                  {(isNewUser || isInstructionOverlayOpen)
                                   && mounted && createPortal(
                                    <div class="swipe-text swipe-un-auth-text question">
                                      No. of question(s) remaining
                                      <MaterialIcon icon="call_received"
                                        size={36}
                                        color={'#ffffff'}/>
                                    </div>,
                                    document.getElementById('bottom-progressbar-container'),
                                  )}
                                  <div class="progress" >
                                    <div class="progress-bar" style={{ width: `${currentCardIndex === 1 ? '0' : (((currentCardIndex - 1) * 100) / totalCards)}%` }}></div>
                                  </div>
                                  <div className="progress-info" style={{}}>
                                    {(currentCardIndex - 1)} of {totalCards} answered
                                  </div>
                                </div>

                                <div class="mx-auto offset-md-2">
                                  <button
                                    onClick={() => {
                                      this.cardRotateAnimationHandler(null, null);
                                      setTimeout(() => {
                                        this.skipQuestion();
                                      }, 500);
                                    }}

                                    onKeyPress={this.stopKeyboardPress}
                                    className="skip-response-button"
                                    style={{
                                      cursor: 'pointer',
                                      border: '2px solid #eb590b',
                                      borderRadius: 'inherit',
                                    }}
                                    disabled={
                                      isSkipButtonClicked
                                      || optionalCommentCount
                                      > questionnaireConstant.OPTIONAL_COMMENT_LIMIT
                                    }
                                  >
                                    <small className="not-sure">SKIP QUESTION</small>
                                    {/* <small className="t-btn-text btn-undo">
                                SKIP QUESTIONS
                              </small> */}
                                  </button>

                                </div>
                              </div>

                            </div>

                          </div>

                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <CheckSoftKeyBoard>
                <footer class="footer-btm">
                  <FooterContent></FooterContent>
                </footer>
              </CheckSoftKeyBoard>
            </div>
          </div>
        </RenderIf>
      </React.Fragment>
    );
  }
}


const mapDispatchToProps = {
  respondUserProjectQuestionnaire,
  setFirstTimeUserFalse,
  undoResponseQuestion,
  updateLastQuestionCardId,
};

const mapStateToProps = state => ({
  questionnaire: state.questionnaire,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CardComponent);
