/* eslint-disable max-len */
import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import '@pwc/appkit-react/style/appkit-react.default.css';
import { connect } from 'react-redux';

class PrivacyPolicy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activePoint: null,
      activePosition: null,
      text: 'Welcome to perspectives',
      tabIndex: 0,
      selectedTab: 'Australia',
    };
  }

  changeTab(tabName) {
    this.setState({
      selectedTab: tabName,
    });
  }

  render() {
    // const { selectedTab } = this.state;
    return (
      <React.Fragment>

        <div className="public-container">
          <div style={{ background: '#fff' }}>
            <div className="public-header-privacy">
              <div className="a-pwc-logo-grid" />
              <div class="font-weight-medium a-nowrap app-title">
                {this.props.brandName}
              </div>

            </div>
            <div>
              <h5 className="mt-2 mb-4 text-center"> Information Collection Statement</h5>
              <h6 className="a-font-md mb-4 text-center italic">Last updated July 2022</h6>
            </div>
          </div>
          <div className="privacy-content">
            <div className="container" style={{ maxWidth: '100%' }}>
              <div className="col-md-12">

                <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist" style={{ justifyContent: 'center' }}>
                  <li class="nav-item" role="presentation">
                    <div
                      style={this.state.selectedTab === 'Australia' ? {
                        backgroundColor: 'transparent', color: '#D04A02', fontWeight: '500', padding: '0.5rem 0px 5px', borderBottom: '2px solid #D04A02', borderRadius: '0px', fontSize: '14px', fontFamily: 'PwC Helvetica Neue',
                      }
                        : {
                          backgroundColor: 'transparent', color: 'black', fontWeight: '500', padding: '0.5rem 0px 5px', borderBottom: '2px solid black', borderRadius: '0px', fontSize: '14px', fontFamily: 'PwC Helvetica Neue',
                        }}
                      onClick={() => this.changeTab('Australia')}
                      class={
                        this.state.selectedTab === 'Australia' ? 'nav-link active' : 'nav-link'
                      } id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="div" role="tab" aria-controls="pills-home" aria-selected="true">AUSTRALIA</div>
                  </li>
                  {/* the code for differnt zone commented code */}

                  {/* <li class="nav-item"
                    role="presentation">
                    <button onClick={() => this.changeTab("Singapore")}
                     style={this.state.selectedTab === "Singapore" ? { backgroundColor: 'transparent', color: '#e8613a' , borderBottom: '2px solid #e8613a' } : { backgroundColor: 'transparent', color: 'black' }}
                      class={
                        this.state.selectedTab === "Singapore" ? "nav-link active" : "nav-link"
                      } id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Singapore</button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button onClick={() => this.changeTab("Malaysia")}
                     style={this.state.selectedTab === "Malaysia" ? { backgroundColor: 'transparent', color: '#e8613a' , borderBottom: '2px solid #e8613a' } : { backgroundColor: 'transparent', color: 'black' }}
                      class={
                        this.state.selectedTab === "Malaysia" ? "nav-link active" : "nav-link"
                      } id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">Malaysia</button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button onClick={() => this.changeTab("US")}
                     style={this.state.selectedTab === "US" ? { backgroundColor: 'transparent', color: '#e8613a' , borderBottom: '2px solid #e8613a' } : { backgroundColor: 'transparent', color: 'black' }}
                      class={
                        this.state.selectedTab === "US" ? "nav-link active" : "nav-link"
                      } id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">US</button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button
                      onClick={() => this.changeTab("UK")}
                     style={this.state.selectedTab === "UK" ? { backgroundColor: 'transparent', color: '#e8613a' , borderBottom: '2px solid #e8613a' } : { backgroundColor: 'transparent', color: 'black' }}
                      class={
                        this.state.selectedTab === "UK" ? "nav-link active" : "nav-link"
                      } id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">UK</button>
                  </li> */}
                </ul>
                <div class="tab-content" id="pills-tabContent">
                  <div
                    class={
                      this.state.selectedTab === 'Australia'
                        ? 'tab-pane fade show active'
                        : 'tab-pane fade'
                    } id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                    <div className="privacy-content-container">


                      <p className="mt-0">
                        PricewaterhouseCoopers (PwC, we, us, our) respects and is committed to protecting your privacy. We are bound by the Privacy Act 1998 (Cth) and comply with our obligations under the Australian Privacy Principles.
                      </p>

                      <p>
                        The terms and conditions of this Information Collection Statement (“Statement”) apply to all services and functionality that is provided via PwC’s {this.props.brandnames} Platform (“{this.props.brandnames}”). In this Statement, all capitalised terms shall have the same meaning as set forth in the {this.props.brandnames} Platform (“{this.props.brandnames}”) Terms of Use (“Terms”), unless otherwise stated.
                      </p>

                      <p>
                        By using {this.props.brandnames}, you agree that PwC may collect and use your personal information in accordance with PwC’s Privacy Policy which is available at <a className='hyperlink'
                          href="https://www.pwc.com.au/contact-us/privacy-policy.html"
                          target="_blank"
                        >
                          {' '}
                          www.pwc.com.au/privacy
                        </a> and this Statement. To the extent that there are any inconsistencies between this Statement and our <a className='hyperlink'
                          href="https://www.pwc.com.au/contact-us/privacy-policy.html"
                          target="_blank"
                        >
                          {' '}
                          Privacy Policy
                        </a>, this Statement shall prevail.
                      </p>

                      <p>
                        We collect personal information directly from you as part of the user registration process (e.g. first name, last name, email address and phone number) to enable you to:
                        <ul className="privacy-list">
                          <li>access and use {this.props.brandnames};</li>
                          <li>manage your account; and</li>
                          <li>share insights on Topics.</li>
                        </ul>
                      </p>

                      <p>
                        We may also collect personal information directly from you or your employer for the following purposes:
                        <ul className="privacy-list">
                          <li>to share anonymised insights with other authorised users;</li>
                          <li>to provide, operate and maintain {this.props.brandnames};</li>
                          <li>to validate your identity (when you sign-in to {this.props.brandnames});</li>
                          <li>to allow you to participate in interactive features within {this.props.brandnames} and to personalise our services;</li>
                          <li>to conduct data matching using other information sources that are directly provided to us which could enhance insights (for example: your role, tenure, organisational unit);</li>
                          <li>to enforce compliance with the {this.props.brandnames} Terms; and</li>
                          <li>for any other purposes with your consent.</li>
                        </ul>
                      </p>

                      <p>
                        Your personal information was either given to us by your employer, or you consented to us collecting information by accessing {this.props.brandnames} and agreeing to the Terms.
                      </p>

                      <p>
                        In providing our services via {this.props.brandnames} we collect, store and process your personal and organisational data and we also track data on how you use our services to conduct research and development so we can improve the services and provide a better user experience.
                      </p>

                      <p>
                        We only disclose your personal information as described in our Statement, and you consent to us disclosing your personal information on that basis.
                      </p>

                      <p>
                        If you do not provide us with your personal information this may prevent you from being able to use {this.props.brandnames}.
                      </p>

                      <p>
                        We may communicate with you in a number of ways (e.g. via email or via text notifications if you choose to register your mobile number with us). PwC may send service or transactional messages to you, for example: alerting you to new features or informing you of any scheduled downtime.
                      </p>

                      <p>
                        We generally don’t collect sensitive information about you, unless you provide it to us voluntarily. You consent to us collecting sensitive information which you provide to us voluntarily, in accordance with this Statement.
                      </p>

                      <p>
                        PwC will retain the personal information for as long as it is reasonably necessary for us to provide our services. PwC will also retain anonymised data for the purposes of analytics and benchmarking, and so we can provide further insights as part of our services.
                      </p>

                      <p>
                        PwC may use third party cloud hosting providers to deliver {this.props.brandnames}. You acknowledge and agree that any information that you or any other person input into {this.props.brandnames} (including any personal information) will therefore be transferred to the hosting provider (who may in turn provide the information to other parties).
                      </p>

                      <p>
                        {this.props.brandnames} uses an Identity and Access Management (IAM) service from PricewaterhouseCoopers IT Services Limited (PwC IT Services) for secure authentication. The IAM service is hosted in privately managed data centres located in the United States, Germany and Singapore. Users may be redirected to the IAM service as part of the PwC user account registration and authentication process. PwC IT Services may not be subject to privacy obligations that are similar to the Australian Privacy Principles, and may be subject to a foreign law that could compel the disclosure of information (including personal information) to a third party, such as an overseas authority.
                      </p>

                      <p>
                        With the exception of your email address, name and phone number (if provided to us for authentication purposes), no personal information will be stored outside of Australia in the regular course of business.
                      </p>

                      <p>
                        PwC employs administrative, physical and electronic measures designed to protect your information from unauthorised access, however we cannot guarantee that unauthorised access will never occur.
                      </p>

                      <p>
                        Except as outlined in this Statement, PwC does not share personal information about PwC Clients or Users with third parties unless we reasonably believe that we are legally obliged to do so, or it is required to protect our property or other legal rights or the rights or property of others.
                        As {this.props.brandnames} continues to evolve PwC may need to modify this Statement from time to time.
                        PwC’s privacy practices may also change to reflect updates in legislation or practice. In the event that this occurs we will use reasonable efforts to communicate these changes via our website by indicating the date the relevant policy or practice was last updated at the top of this Statement. We encourage you to review the Statement each time you visit our website to see if it has been updated, in order to make sure you understand how personal information you provide will be used. For the avoidance of doubt, your continued use of {this.props.brandnames} constitutes your agreement to this Statement and any updates.

                      </p>

                      <p>
                        You have a right to access any personal information we hold about you, and you can ask us to update, correct or delete your personal information. For more information on how you can access your personal information, opt-out, or contact us with any questions, concerns or privacy related complaints please see our Privacy Policy which is available at <a className='hyperlink'
                          href="https://www.pwc.com.au/contact-us/privacy-policy.html"
                          target="_blank"
                        >
                          {' '}
                          www.pwc.com.au/privacy
                        </a>
                        &nbsp; or contact us at{' '} <a href="mailto:au_privacy_officer_mbx@pwc.com">
                          au_privacy_officer_mbx@pwc.com
                        </a>.
                      </p>
                    </div>
                  </div>
                  <div class={
                    this.state.selectedTab === 'Singapore'
                      ? 'tab-pane fade show active'
                      : 'tab-pane fade'
                  } id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                    <div className="privacy-content-container">


                      <p className="mt-0">
                        PricewaterhouseCoopers (PwC, we, us, our) respects and is committed to protecting your privacy. We are bound by the Privacy Act 1998 (Cth) and comply with our obligations under the Australian Privacy Principles.
                      </p>

                      <p>
                        The terms and conditions of this Information Collection Statement (“Statement”) apply to all services and functionality that is provided via PwC’s {this.props.brandnames} Platform (“{this.props.brandnames}”). In this Statement, all capitalised terms shall have the same meaning as set forth in the {this.props.brandnames} Platform (“{this.props.brandnames}”) Terms of Use (“Terms”), unless otherwise stated.
                      </p>

                      <p>
                        By using {this.props.brandnames}, you agree that PwC may collect and use your personal information in accordance with PwC’s Privacy Policy which is available at <a className='hyperlink'
                          href="https://www.pwc.com.au/contact-us/privacy-policy.html"
                          target="_blank"
                        >
                          {' '}
                          www.pwc.com.au/privacy
                        </a> and this Statement. To the extent that there are any inconsistencies between this Statement and our <a className='hyperlink'
                          href="https://www.pwc.com.au/contact-us/privacy-policy.html"
                          target="_blank"
                        >
                          {' '}
                          Privacy Policy
                        </a>, this Statement shall prevail.
                      </p>

                      <p>
                        We collect personal information directly from you as part of the user registration process (e.g. first name, last name, email address and phone number) to enable you to:
                        <ul className="privacy-list">
                          <li>access and use {this.props.brandnames};</li>
                          <li>manage your account; and</li>
                          <li>share insights on Topics.</li>
                        </ul>
                      </p>

                      <p>
                        We may also collect personal information directly from you or your employer for the following purposes:
                        <ul className="privacy-list">
                          <li>to share anonymised insights with other authorised users;</li>
                          <li>to provide, operate and maintain {this.props.brandnames};</li>
                          <li>to validate your identity (when you sign-in to {this.props.brandnames});</li>
                          <li>to allow you to participate in interactive features within {this.props.brandnames} and to personalise our services;</li>
                          <li>to conduct data matching using other information sources that are directly provided to us which could enhance insights (for example: your role, tenure, organisational unit);</li>
                          <li>to enforce compliance with the {this.props.brandnames} Terms; and</li>
                          <li>for any other purposes with your consent.</li>
                        </ul>
                      </p>

                      <p>
                        Your personal information was either given to us by your employer, or you consented to us collecting information by accessing {this.props.brandnames} and agreeing to the Terms.
                      </p>

                      <p>
                        In providing our services via {this.props.brandnames} we collect, store and process your personal and organisational data and we also track data on how you use our services to conduct research and development so we can improve the services and provide a better user experience.
                      </p>

                      <p>
                        We only disclose your personal information as described in our Statement, and you consent to us disclosing your personal information on that basis.
                      </p>

                      <p>
                        If you do not provide us with your personal information this may prevent you from being able to use {this.props.brandnames}.
                      </p>

                      <p>
                        We may communicate with you in a number of ways (e.g. via email or via text notifications if you choose to register your mobile number with us). PwC may send service or transactional messages to you, for example: alerting you to new features or informing you of any scheduled downtime.
                      </p>

                      <p>
                        We generally don’t collect sensitive information about you, unless you provide it to us voluntarily. You consent to us collecting sensitive information which you provide to us voluntarily, in accordance with this Statement.
                      </p>

                      <p>
                        PwC will retain the personal information for as long as it is reasonably necessary for us to provide our services. PwC will also retain anonymised data for the purposes of analytics and benchmarking, and so we can provide further insights as part of our services.
                      </p>

                      <p>
                        PwC may use third party cloud hosting providers to deliver {this.props.brandnames}. You acknowledge and agree that any information that you or any other person input into {this.props.brandnames} (including any personal information) will therefore be transferred to the hosting provider (who may in turn provide the information to other parties).
                      </p>

                      <p>
                        {this.props.brandnames} uses an Identity and Access Management (IAM) service from PricewaterhouseCoopers IT Services Limited (PwC IT Services) for secure authentication. The IAM service is hosted in privately managed data centres located in the United States, Germany and Singapore. Users may be redirected to the IAM service as part of the PwC user account registration and authentication process. PwC IT Services may not be subject to privacy obligations that are similar to the Australian Privacy Principles, and may be subject to a foreign law that could compel the disclosure of information (including personal information) to a third party, such as an overseas authority.
                      </p>

                      <p>
                        With the exception of your email address, name and phone number (if provided to us for authentication purposes), no personal information will be stored outside of Australia in the regular course of business.
                      </p>

                      <p>
                        PwC employs administrative, physical and electronic measures designed to protect your information from unauthorised access, however we cannot guarantee that unauthorised access will never occur.
                      </p>

                      <p>
                        Except as outlined in this Statement, PwC does not share personal information about PwC Clients or Users with third parties unless we reasonably believe that we are legally obliged to do so, or it is required to protect our property or other legal rights or the rights or property of others.
                        As {this.props.brandnames} continues to evolve PwC may need to modify this Statement from time to time.
                        PwC’s privacy practices may also change to reflect updates in legislation or practice. In the event that this occurs we will use reasonable efforts to communicate these changes via our website by indicating the date the relevant policy or practice was last updated at the top of this Statement. We encourage you to review the Statement each time you visit our website to see if it has been updated, in order to make sure you understand how personal information you provide will be used. For the avoidance of doubt, your continued use of {this.props.brandnames} constitutes your agreement to this Statement and any updates.

                      </p>

                      <p>
                        You have a right to access any personal information we hold about you, and you can ask us to update, correct or delete your personal information. For more information on how you can access your personal information, opt-out, or contact us with any questions, concerns or privacy related complaints please see our Privacy Policy which is available at <a className='hyperlink'
                          href="https://www.pwc.com.au/contact-us/privacy-policy.html"
                          target="_blank"
                        >
                          {' '}
                          www.pwc.com.au/privacy
                        </a>
                        &nbsp; or contact us at{' '} <a href="mailto:au_privacy_officer_mbx@pwc.com">
                          au_privacy_officer_mbx@pwc.com
                        </a>.
                      </p>
                    </div>
                  </div>
                  <div class={
                    this.state.selectedTab === 'Malaysia'
                      ? 'tab-pane fade show active'
                      : 'tab-pane fade'
                  } id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
                    <div className="privacy-content-container">


                      <p className="mt-0">
                        PricewaterhouseCoopers (PwC, we, us, our) respects and is committed to protecting your privacy. We are bound by the Privacy Act 1998 (Cth) and comply with our obligations under the Australian Privacy Principles.
                      </p>

                      <p>
                        The terms and conditions of this Information Collection Statement (“Statement”) apply to all services and functionality that is provided via PwC’s {this.props.brandnames} Platform (“{this.props.brandnames}”). In this Statement, all capitalised terms shall have the same meaning as set forth in the {this.props.brandnames} Platform (“{this.props.brandnames}”) Terms of Use (“Terms”), unless otherwise stated.
                      </p>

                      <p>
                        By using {this.props.brandnames}, you agree that PwC may collect and use your personal information in accordance with PwC’s Privacy Policy which is available at <a className='hyperlink'
                          href="https://www.pwc.com.au/contact-us/privacy-policy.html"
                          target="_blank"
                        >
                          {' '}
                          www.pwc.com.au/privacy
                        </a> and this Statement. To the extent that there are any inconsistencies between this Statement and our <a className='hyperlink'
                          href="https://www.pwc.com.au/contact-us/privacy-policy.html"
                          target="_blank"
                        >
                          {' '}
                          Privacy Policy
                        </a>, this Statement shall prevail.
                      </p>

                      <p>
                        We collect personal information directly from you as part of the user registration process (e.g. first name, last name, email address and phone number) to enable you to:
                        <ul className="privacy-list">
                          <li>access and use {this.props.brandnames};</li>
                          <li>manage your account; and</li>
                          <li>share insights on Topics.</li>
                        </ul>
                      </p>

                      <p>
                        We may also collect personal information directly from you or your employer for the following purposes:
                        <ul className="privacy-list">
                          <li>to share anonymised insights with other authorised users;</li>
                          <li>to provide, operate and maintain {this.props.brandnames};</li>
                          <li>to validate your identity (when you sign-in to {this.props.brandnames});</li>
                          <li>to allow you to participate in interactive features within {this.props.brandnames} and to personalise our services;</li>
                          <li>to conduct data matching using other information sources that are directly provided to us which could enhance insights (for example: your role, tenure, organisational unit);</li>
                          <li>to enforce compliance with the {this.props.brandnames} Terms; and</li>
                          <li>for any other purposes with your consent.</li>
                        </ul>
                      </p>

                      <p>
                        Your personal information was either given to us by your employer, or you consented to us collecting information by accessing {this.props.brandnames} and agreeing to the Terms.
                      </p>

                      <p>
                        In providing our services via {this.props.brandnames} we collect, store and process your personal and organisational data and we also track data on how you use our services to conduct research and development so we can improve the services and provide a better user experience.
                      </p>

                      <p>
                        We only disclose your personal information as described in our Statement, and you consent to us disclosing your personal information on that basis.
                      </p>

                      <p>
                        If you do not provide us with your personal information this may prevent you from being able to use {this.props.brandnames}.
                      </p>

                      <p>
                        We may communicate with you in a number of ways (e.g. via email or via text notifications if you choose to register your mobile number with us). PwC may send service or transactional messages to you, for example: alerting you to new features or informing you of any scheduled downtime.
                      </p>

                      <p>
                        We generally don’t collect sensitive information about you, unless you provide it to us voluntarily. You consent to us collecting sensitive information which you provide to us voluntarily, in accordance with this Statement.
                      </p>

                      <p>
                        PwC will retain the personal information for as long as it is reasonably necessary for us to provide our services. PwC will also retain anonymised data for the purposes of analytics and benchmarking, and so we can provide further insights as part of our services.
                      </p>

                      <p>
                        PwC may use third party cloud hosting providers to deliver {this.props.brandnames}. You acknowledge and agree that any information that you or any other person input into {this.props.brandnames} (including any personal information) will therefore be transferred to the hosting provider (who may in turn provide the information to other parties).
                      </p>

                      <p>
                        {this.props.brandnames} uses an Identity and Access Management (IAM) service from PricewaterhouseCoopers IT Services Limited (PwC IT Services) for secure authentication. The IAM service is hosted in privately managed data centres located in the United States, Germany and Singapore. Users may be redirected to the IAM service as part of the PwC user account registration and authentication process. PwC IT Services may not be subject to privacy obligations that are similar to the Australian Privacy Principles, and may be subject to a foreign law that could compel the disclosure of information (including personal information) to a third party, such as an overseas authority.
                      </p>

                      <p>
                        With the exception of your email address, name and phone number (if provided to us for authentication purposes), no personal information will be stored outside of Australia in the regular course of business.
                      </p>

                      <p>
                        PwC employs administrative, physical and electronic measures designed to protect your information from unauthorised access, however we cannot guarantee that unauthorised access will never occur.
                      </p>

                      <p>
                        Except as outlined in this Statement, PwC does not share personal information about PwC Clients or Users with third parties unless we reasonably believe that we are legally obliged to do so, or it is required to protect our property or other legal rights or the rights or property of others.
                        As {this.props.brandnames} continues to evolve PwC may need to modify this Statement from time to time.
                        PwC’s privacy practices may also change to reflect updates in legislation or practice. In the event that this occurs we will use reasonable efforts to communicate these changes via our website by indicating the date the relevant policy or practice was last updated at the top of this Statement. We encourage you to review the Statement each time you visit our website to see if it has been updated, in order to make sure you understand how personal information you provide will be used. For the avoidance of doubt, your continued use of {this.props.brandnames} constitutes your agreement to this Statement and any updates.

                      </p>

                      <p>
                        You have a right to access any personal information we hold about you, and you can ask us to update, correct or delete your personal information. For more information on how you can access your personal information, opt-out, or contact us with any questions, concerns or privacy related complaints please see our Privacy Policy which is available at <a className='hyperlink'
                          href="https://www.pwc.com.au/contact-us/privacy-policy.html"
                          target="_blank"
                        >
                          {' '}
                          www.pwc.com.au/privacy
                        </a>
                        &nbsp; or contact us at{' '} <a href="mailto:au_privacy_officer_mbx@pwc.com">
                          au_privacy_officer_mbx@pwc.com
                        </a>.
                      </p>
                    </div>
                  </div>
                  <div class={
                    this.state.selectedTab === 'US'
                      ? 'tab-pane fade show active'
                      : 'tab-pane fade'
                  } id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
                    <div className="privacy-content-container">


                      <p className="mt-0">
                        PricewaterhouseCoopers (PwC, we, us, our) respects and is committed to protecting your privacy. We are bound by the Privacy Act 1998 (Cth) and comply with our obligations under the Australian Privacy Principles.
                      </p>

                      <p>
                        The terms and conditions of this Information Collection Statement (“Statement”) apply to all services and functionality that is provided via PwC’s {this.props.brandnames} Platform (“{this.props.brandnames}”). In this Statement, all capitalised terms shall have the same meaning as set forth in the {this.props.brandnames} Platform (“{this.props.brandnames}”) Terms of Use (“Terms”), unless otherwise stated.
                      </p>

                      <p>
                        By using {this.props.brandnames}, you agree that PwC may collect and use your personal information in accordance with PwC’s Privacy Policy which is available at <a className='hyperlink'
                          href="https://www.pwc.com.au/contact-us/privacy-policy.html"
                          target="_blank"
                        >
                          {' '}
                          www.pwc.com.au/privacy
                        </a> and this Statement. To the extent that there are any inconsistencies between this Statement and our <a className='hyperlink'
                          href="https://www.pwc.com.au/contact-us/privacy-policy.html"
                          target="_blank"
                        >
                          {' '}
                          Privacy Policy
                        </a>, this Statement shall prevail.
                      </p>

                      <p>
                        We collect personal information directly from you as part of the user registration process (e.g. first name, last name, email address and phone number) to enable you to:
                        <ul className="privacy-list">
                          <li>access and use {this.props.brandnames};</li>
                          <li>manage your account; and</li>
                          <li>share insights on Topics.</li>
                        </ul>
                      </p>

                      <p>
                        We may also collect personal information directly from you or your employer for the following purposes:
                        <ul className="privacy-list">
                          <li>to share anonymised insights with other authorised users;</li>
                          <li>to provide, operate and maintain {this.props.brandnames};</li>
                          <li>to validate your identity (when you sign-in to {this.props.brandnames});</li>
                          <li>to allow you to participate in interactive features within {this.props.brandnames} and to personalise our services;</li>
                          <li>to conduct data matching using other information sources that are directly provided to us which could enhance insights (for example: your role, tenure, organisational unit);</li>
                          <li>to enforce compliance with the {this.props.brandnames} Terms; and</li>
                          <li>for any other purposes with your consent.</li>
                        </ul>
                      </p>

                      <p>
                        Your personal information was either given to us by your employer, or you consented to us collecting information by accessing {this.props.brandnames} and agreeing to the Terms.
                      </p>

                      <p>
                        In providing our services via {this.props.brandnames} we collect, store and process your personal and organisational data and we also track data on how you use our services to conduct research and development so we can improve the services and provide a better user experience.
                      </p>

                      <p>
                        We only disclose your personal information as described in our Statement, and you consent to us disclosing your personal information on that basis.
                      </p>

                      <p>
                        If you do not provide us with your personal information this may prevent you from being able to use {this.props.brandnames}.
                      </p>

                      <p>
                        We may communicate with you in a number of ways (e.g. via email or via text notifications if you choose to register your mobile number with us). PwC may send service or transactional messages to you, for example: alerting you to new features or informing you of any scheduled downtime.
                      </p>

                      <p>
                        We generally don’t collect sensitive information about you, unless you provide it to us voluntarily. You consent to us collecting sensitive information which you provide to us voluntarily, in accordance with this Statement.
                      </p>

                      <p>
                        PwC will retain the personal information for as long as it is reasonably necessary for us to provide our services. PwC will also retain anonymised data for the purposes of analytics and benchmarking, and so we can provide further insights as part of our services.
                      </p>

                      <p>
                        PwC may use third party cloud hosting providers to deliver {this.props.brandnames}. You acknowledge and agree that any information that you or any other person input into {this.props.brandnames} (including any personal information) will therefore be transferred to the hosting provider (who may in turn provide the information to other parties).
                      </p>

                      <p>
                        {this.props.brandnames} uses an Identity and Access Management (IAM) service from PricewaterhouseCoopers IT Services Limited (PwC IT Services) for secure authentication. The IAM service is hosted in privately managed data centres located in the United States, Germany and Singapore. Users may be redirected to the IAM service as part of the PwC user account registration and authentication process. PwC IT Services may not be subject to privacy obligations that are similar to the Australian Privacy Principles, and may be subject to a foreign law that could compel the disclosure of information (including personal information) to a third party, such as an overseas authority.
                      </p>

                      <p>
                        With the exception of your email address, name and phone number (if provided to us for authentication purposes), no personal information will be stored outside of Australia in the regular course of business.
                      </p>

                      <p>
                        PwC employs administrative, physical and electronic measures designed to protect your information from unauthorised access, however we cannot guarantee that unauthorised access will never occur.
                      </p>

                      <p>
                        Except as outlined in this Statement, PwC does not share personal information about PwC Clients or Users with third parties unless we reasonably believe that we are legally obliged to do so, or it is required to protect our property or other legal rights or the rights or property of others.
                        As {this.props.brandnames} continues to evolve PwC may need to modify this Statement from time to time.
                        PwC’s privacy practices may also change to reflect updates in legislation or practice. In the event that this occurs we will use reasonable efforts to communicate these changes via our website by indicating the date the relevant policy or practice was last updated at the top of this Statement. We encourage you to review the Statement each time you visit our website to see if it has been updated, in order to make sure you understand how personal information you provide will be used. For the avoidance of doubt, your continued use of {this.props.brandnames} constitutes your agreement to this Statement and any updates.

                      </p>

                      <p>
                        You have a right to access any personal information we hold about you, and you can ask us to update, correct or delete your personal information. For more information on how you can access your personal information, opt-out, or contact us with any questions, concerns or privacy related complaints please see our Privacy Policy which is available at <a className='hyperlink'
                          href="https://www.pwc.com.au/contact-us/privacy-policy.html"
                          target="_blank"
                        >
                          {' '}
                          www.pwc.com.au/privacy
                        </a>
                        &nbsp; or contact us at{' '} <a href="mailto:au_privacy_officer_mbx@pwc.com">
                          au_privacy_officer_mbx@pwc.com
                        </a>.
                      </p>
                    </div>
                  </div>
                  <div class={
                    this.state.selectedTab === 'UK'
                      ? 'tab-pane fade show active'
                      : 'tab-pane fade'
                  } id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
                    <div className="privacy-content-container">


                      <p className="mt-0">
                        PricewaterhouseCoopers (PwC, we, us, our) respects and is committed to protecting your privacy. We are bound by the Privacy Act 1998 (Cth) and comply with our obligations under the Australian Privacy Principles.
                      </p>

                      <p>
                        The terms and conditions of this Information Collection Statement (“Statement”) apply to all services and functionality that is provided via PwC’s {this.props.brandnames} Platform (“{this.props.brandnames}”). In this Statement, all capitalised terms shall have the same meaning as set forth in the {this.props.brandnames} Platform (“{this.props.brandnames}”) Terms of Use (“Terms”), unless otherwise stated.
                      </p>

                      <p>
                        By using {this.props.brandnames}, you agree that PwC may collect and use your personal information in accordance with PwC’s Privacy Policy which is available at <a className='hyperlink'
                          href="https://www.pwc.com.au/contact-us/privacy-policy.html"
                          target="_blank"
                        >
                          {' '}
                          www.pwc.com.au/privacy
                        </a> and this Statement. To the extent that there are any inconsistencies between this Statement and our <a className='hyperlink'
                          href="https://www.pwc.com.au/contact-us/privacy-policy.html"
                          target="_blank"
                        >
                          {' '}
                          Privacy Policy
                        </a>, this Statement shall prevail.
                      </p>

                      <p>
                        We collect personal information directly from you as part of the user registration process (e.g. first name, last name, email address and phone number) to enable you to:
                        <ul className="privacy-list">
                          <li>access and use {this.props.brandnames};</li>
                          <li>manage your account; and</li>
                          <li>share insights on Topics.</li>
                        </ul>
                      </p>

                      <p>
                        We may also collect personal information directly from you or your employer for the following purposes:
                        <ul className="privacy-list">
                          <li>to share anonymised insights with other authorised users;</li>
                          <li>to provide, operate and maintain {this.props.brandnames};</li>
                          <li>to validate your identity (when you sign-in to {this.props.brandnames});</li>
                          <li>to allow you to participate in interactive features within {this.props.brandnames} and to personalise our services;</li>
                          <li>to conduct data matching using other information sources that are directly provided to us which could enhance insights (for example: your role, tenure, organisational unit);</li>
                          <li>to enforce compliance with the {this.props.brandnames} Terms; and</li>
                          <li>for any other purposes with your consent.</li>
                        </ul>
                      </p>

                      <p>
                        Your personal information was either given to us by your employer, or you consented to us collecting information by accessing {this.props.brandnames} and agreeing to the Terms.
                      </p>

                      <p>
                        In providing our services via {this.props.brandnames} we collect, store and process your personal and organisational data and we also track data on how you use our services to conduct research and development so we can improve the services and provide a better user experience.
                      </p>

                      <p>
                        We only disclose your personal information as described in our Statement, and you consent to us disclosing your personal information on that basis.
                      </p>

                      <p>
                        If you do not provide us with your personal information this may prevent you from being able to use {this.props.brandnames}.
                      </p>

                      <p>
                        We may communicate with you in a number of ways (e.g. via email or via text notifications if you choose to register your mobile number with us). PwC may send service or transactional messages to you, for example: alerting you to new features or informing you of any scheduled downtime.
                      </p>

                      <p>
                        We generally don’t collect sensitive information about you, unless you provide it to us voluntarily. You consent to us collecting sensitive information which you provide to us voluntarily, in accordance with this Statement.
                      </p>

                      <p>
                        PwC will retain the personal information for as long as it is reasonably necessary for us to provide our services. PwC will also retain anonymised data for the purposes of analytics and benchmarking, and so we can provide further insights as part of our services.
                      </p>

                      <p>
                        PwC may use third party cloud hosting providers to deliver {this.props.brandnames}. You acknowledge and agree that any information that you or any other person input into {this.props.brandnames} (including any personal information) will therefore be transferred to the hosting provider (who may in turn provide the information to other parties).
                      </p>

                      <p>
                        {this.props.brandnames} uses an Identity and Access Management (IAM) service from PricewaterhouseCoopers IT Services Limited (PwC IT Services) for secure authentication. The IAM service is hosted in privately managed data centres located in the United States, Germany and Singapore. Users may be redirected to the IAM service as part of the PwC user account registration and authentication process. PwC IT Services may not be subject to privacy obligations that are similar to the Australian Privacy Principles, and may be subject to a foreign law that could compel the disclosure of information (including personal information) to a third party, such as an overseas authority.
                      </p>

                      <p>
                        With the exception of your email address, name and phone number (if provided to us for authentication purposes), no personal information will be stored outside of Australia in the regular course of business.
                      </p>

                      <p>
                        PwC employs administrative, physical and electronic measures designed to protect your information from unauthorised access, however we cannot guarantee that unauthorised access will never occur.
                      </p>

                      <p>
                        Except as outlined in this Statement, PwC does not share personal information about PwC Clients or Users with third parties unless we reasonably believe that we are legally obliged to do so, or it is required to protect our property or other legal rights or the rights or property of others.
                        As {this.props.brandnames} continues to evolve PwC may need to modify this Statement from time to time.
                        PwC’s privacy practices may also change to reflect updates in legislation or practice. In the event that this occurs we will use reasonable efforts to communicate these changes via our website by indicating the date the relevant policy or practice was last updated at the top of this Statement. We encourage you to review the Statement each time you visit our website to see if it has been updated, in order to make sure you understand how personal information you provide will be used. For the avoidance of doubt, your continued use of {this.props.brandnames} constitutes your agreement to this Statement and any updates.

                      </p>

                      <p>
                        You have a right to access any personal information we hold about you, and you can ask us to update, correct or delete your personal information. For more information on how you can access your personal information, opt-out, or contact us with any questions, concerns or privacy related complaints please see our Privacy Policy which is available at <a className='hyperlink'
                          href="https://www.pwc.com.au/contact-us/privacy-policy.html"
                          target="_blank"
                        >
                          {' '}
                          www.pwc.com.au/privacy
                        </a>
                        &nbsp; or contact us at{' '} <a href="mailto:au_privacy_officer_mbx@pwc.com">
                          au_privacy_officer_mbx@pwc.com
                        </a>.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}


const mapDispatchToProps = {
};
const mapStateToProps = () => ({
  brandName: 'Perspectives',
});
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PrivacyPolicy);
